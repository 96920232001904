import React from 'react'
import { Container, Nav, Image, Stack, Row } from 'react-bootstrap'
import Logo from '../../assets/master/images/logo.png'
import Background from '../../assets/master/images/backgrounds/bg-4.webp'
import '../../assets/master/css/components/Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    function moveTop() {
        document.documentElement.scrollTop = 0
    }
    return (
        <Stack className="pt-5 footer" style={{ backgroundImage: `url(${Background})` }}>
            <Container fluid={'xxl'} className=" pt-2 px-lg-5">
                <Row className="g-4">
                    <Stack className="col-lg-4 col-12 mb-3">
                        <Link to={'/'} onClick={moveTop}>
                            <Image src={Logo} className="mb-4 col-lg-5 col-md-3 col-5" alt="OwaSoft Logo" loading="lazy" />
                        </Link>
                        <p className="footerContent me-md-4 m-0 text-white">OwaSoft Technologies is an IT Software Company in Pakistan registered with SECP, through our innovative technological solutions and assistance from our remarkable teams, we empower your Business.</p>
                    </Stack>
                    <Stack className="col-lg-2 col-md-4 col-6 mb-3 footer-links">
                        <h4 className="text-white">Quick Links</h4>
                        <Nav className="d-flex flex-column">
                            <Link className="text-white nav-link px-0" to={'d'}>
                                About Us
                            </Link>
                            <Link className="text-white nav-link px-0" to={'d'}>
                                Technologies
                            </Link>
                            <Link className="text-white nav-link px-0" to={'d'}>
                                Solutions
                            </Link>
                            <Link to={'d'} className="text-white nav-link px-0" onClick={moveTop}>
                                Products
                            </Link>
                            <Link className="text-white nav-link px-0" to={'d'}>
                                Portfolio
                            </Link>
                        </Nav>
                    </Stack>
                    <div className="col-lg-2  col-md-4 col-6 mb-3 footer-links">
                        <h4 className="text-white px-0">Services</h4>
                        <Nav className="d-flex flex-column">
                            <Link className="text-white nav-link px-0" to={'/services/ui-ux'} title="UI / UX Designing" onClick={moveTop}>
                                UI / UX Designing
                            </Link>
                            <Link className="text-white nav-link px-0" to={'/web-designing'} title="Web Designing" onClick={moveTop}>
                                Web Designing
                            </Link>
                            <Link className="text-white nav-link px-0" title="/eCommerce" to={'d'} onClick={moveTop}>
                                eCommerce
                            </Link>
                            <Link className="text-white nav-link px-0" to={'/api-development'} title="API Development">
                                API Development
                            </Link>
                            <Link className="text-white nav-link px-0" to={'d'} title="DevOps">
                                DevOps
                            </Link>
                        </Nav>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3 footer-links">
                        <h4 className="text-white">Contact Us</h4>
                        <div className="nav flex-column text-white">
                            <div className="d-flex flex-row">
                                <span>
                                    <svg width="20" height="20" fill="currentColor" className="me-3" viewBox="0 0 16 16">
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                    </svg>
                                </span>
                                <address>Office Number S-15 Gold Point Plaza Murree Road Rawalpindi, Pakistan.</address>
                            </div>
                            <div className="d-flex">
                                <span>
                                    <svg width="20" height="20" fill="currentColor" className="me-3" viewBox="0 0 16 16">
                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                    </svg>
                                </span>
                                <address>nfo@owasoftltd.com</address>
                            </div>
                            <div className="d-flex">
                                <span>
                                    <svg width="20" height="20" fill="currentColor" className="me-3" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                    </svg>
                                </span>
                                <p>+923344422266</p>
                            </div>
                        </div>
                    </div>
                    <div className=" text-white d-flex flex-lg-row flex-column gap-x-5 justify-content-center justify-content-lg-between align-items-center">
                        <ul className="list-unstyled d-flex socialIcon">
                            <li className="ms-0">
                                <a href="https://www.instagram.com/owasofttech/" className="link-dark" title="Instagram" target="_blank">
                                    <svg width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />{' '}
                                    </svg>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="link-dark" href="https://www.linkedin.com/company/owasoft-technologies-pvt-limited/" target="_blank" title="LinkedIn">
                                    <svg width="20" height="20" fill="currentColor" viewBox="0 0 12.322 11.774">
                                        <g data-name="Group 6">
                                            <g data-name="Group 5">
                                                <path data-name="Path 2" d="M64.051 6260.624h2.628v1.09c.047-.055.073-.08.093-.108a2.6 2.6 0 0 1 2.308-1.178 2.872 2.872 0 0 1 1.948.674 2.953 2.953 0 0 1 .949 1.726 6.27 6.27 0 0 1 .118 1.119c.014 1.5.006 2.99.006 4.485v.114h-2.639v-.172c0-1.339.005-2.677 0-4.016a3.9 3.9 0 0 0-.1-.855 1.14 1.14 0 0 0-.857-.945 1.383 1.383 0 0 0-1.408.4 1.614 1.614 0 0 0-.419 1.181c.007 1.407 0 2.815 0 4.225v.187h-2.63z" transform="translate(-59.781 -6256.781)" />
                                                <path data-name="Path 3" d="M62.57 6268.555h-2.616v-7.931h2.616z" transform="translate(-59.781 -6256.781)" />
                                                <path data-name="Path 4" d="M62.753 6258.169a1.339 1.339 0 0 1-1.177 1.335 1.722 1.722 0 0 1-1.069-.146 1.356 1.356 0 0 1 .322-2.521 1.829 1.829 0 0 1 .927.008 1.327 1.327 0 0 1 .997 1.324z" transform="translate(-59.781 -6256.781)" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="link-dark" href="https://www.youtube.com/channel/UCL9GJmUrf_dVlF7YnjdeGeg" title="Youtube" target="_blank">
                                    <svg width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />{' '}
                                    </svg>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="link-dark" href="https://www.facebook.com/owasoftltd" title="Facebook" target="_blank">
                                    <svg width="20" height="20" fill="currentColor" viewBox="0 0 6.651 13.286">
                                        <g data-name="Group 7">
                                            <path data-name="Path 5" d="M105.78 6269.311v-6.893h-1.416v-1.992h1.418v-.2c.009-.626 0-1.254.031-1.88a2.356 2.356 0 0 1 2.407-2.307 11.346 11.346 0 0 1 2.565.184c.071.014.141.034.225.054l-.354 2.02a7.681 7.681 0 0 0-.831-.126 3.174 3.174 0 0 0-.813.048.649.649 0 0 0-.564.722v1.332c0 .046.006.092.01.153h2.268l-.158 1.991h-2.118v6.9z" transform="translate(-104.364 -6256.025)" />
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                        <p className="text-center mt-4 mt-lg-0">
                            &copy; 2021 - {new Date().getFullYear()} <span className="mainColor">OWASOFT</span> All rights reserved.
                        </p>
                    </div>
                </Row>
            </Container>
        </Stack>
    )
}

export default Footer
