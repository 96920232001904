import React from 'react'
import Logo from '../../assets/master/images/logo.png'
import '../../assets/master/css/components/Header.css'

import { Container, Nav, Navbar, Image } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
function MenuBar() {

    return (
        <Navbar expand="lg" className="py-3 py-lg-0 position-absolute w-100" variant="dark">
            <Container fluid="xxl" className=" pt-2 px-lg-5 px-3">
                <Link to="/">
                    <Image src={Logo} height="43" alt="OsaSoft Logo" loading="lazy" />
                </Link>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll" className="navBarSmall">
                    <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                        <NavLink to={'/'} className=" nav-link p-3 py-lg-4" end>
                            Home
                        </NavLink>
                        <NavLink to={'/services'} className="nav-link p-3 py-lg-4">
                            Services
                        </NavLink>
                        <NavLink to={'/our-work'} className="nav-link p-3 py-lg-4">
                            Our Work
                        </NavLink>
                        <NavLink to={'../contact-us'} className="p-3 nav-link py-lg-4">
                            Contact Us
                        </NavLink>
                    </Nav>
                    <Nav.Item href="#" className=" btn btn-sm defaultButton ms-3 px-3" title="Free Quota">
                        Free Quota <span />
                    </Nav.Item>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MenuBar
