import React from 'react'
import { Row, Container, Stack, Button, Image } from 'react-bootstrap'
import emailjs from '@emailjs/browser'

import Background from '../assets/master/images/backgrounds/product-banner.webp'
import backgroundImage5 from '../assets/master/images/backgrounds/bg3.webp'
import '../assets/master/css/contactUs.css'
import HeroSection from './components/HeroSection'

import Image01 from '../assets/master/images/card-icons/Group-35783.svg'
import Image02 from '../assets/master/images/card-icons/faq.svg'
import Image03 from '../assets/master/images/card-icons/Group-5785.svg'
import { useRef } from 'react'

function ContactUs() {
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
    }
    const background5 = {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage5})`,
    }

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_wo9hv8c', 'template_bfmaqnj', form.current, 'T2EFh1SeBYPUpDbr1').then(
            (result) => {
                console.log(result.text)
            },
            (error) => {
                console.log(error.text)
            }
        )
        e.target.reset()
    }
    document.title = 'Contact Us | OwaSoft'
    return (
        <Stack className="main">
            <Stack className="pt-5" style={MainBanner}>
                <Container fluid="xl">
                    <Row className="col-md-8 flex-column align-content-center justify-content-center my-5  py-md-5">
                        <h1 className="display-3 text-white fw-bold text-uppercase">Hire Owasoft</h1>
                        <h6 className="my-3 text-white lead">Let us take your ideas and give them flight! Drop us a note here and we will get back to you within 24 hours.</h6>
                        <h6 className="my-3 text-white lead">
                            Home - <span className="mainColor">Contact Us </span>
                        </h6>
                    </Row>
                </Container>
            </Stack>
            <Container fluid="xl">
                <Row className="py-5">
                    <h2 className="fw-bold mainColor col-md-6 mb-5">Ready to build your product the 'OWASOFT' way?</h2>
                    <Stack>
                        <form className="g-4 row contactUs lead fw-bold" ref={form} onSubmit={sendEmail}>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="user_name" className="form-label">
                                        Full Name
                                    </label>
                                    <input type="text" name="user_name" className="p-3" placeholder="Please Enter" required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="companyName" className="form-label">
                                        Company Name
                                    </label>
                                    <input type="text" name="companyName" className="p-3" placeholder="Please Enter" required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="user_email" className="form-label">
                                        Email Address
                                    </label>
                                    <input type="text" name="user_email" className="p-3" placeholder="Please Enter" required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="contactNumber" className="form-label">
                                        Contact Number
                                    </label>
                                    <div className="d-flex contact p-0">
                                        <input type="text" name="contactNumber" className="p-3 w-100" placeholder="Please Enter" required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="project_type" className="form-label">
                                        Project Type
                                    </label>
                                    <select className="p-3" name="project_type" id="" required>
                                        <option value="" disabled selected>
                                            Please Select
                                        </option>
                                        <option value="Web Application">Web Application</option>
                                        <option value="Mobile application">Mobile application</option>
                                        <option value="Web & Mobile Application">Web & Mobile Application</option>
                                        <option value="Customer Software">Customer Software</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="Budget" className="form-label">
                                        Approx. Budget*
                                    </label>
                                    <select className="p-3" name="Budget" required>
                                        <option selected disabled value="">
                                            Please Select
                                        </option>
                                        <option value="$5,000-$10,000">$5,000-$10,000</option>
                                        <option value="$10,000-$15,000">$10,001-$15,000</option>
                                        <option value="$15,000-$20,000">$15,001-$20,000</option>
                                        <option value="$20,000-$25,000">$20,001-$25,000</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="aboutUs" className="form-label">
                                        How did you hear about us?*
                                    </label>
                                    <textarea name="aboutUs" id="aboutUs" className="p-3" cols="30" rows="5" placeholder="how you find us?" required></textarea>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="py-2 d-flex flex-column">
                                    <label htmlFor="message" className="form-label">
                                        How can we help?
                                    </label>
                                    <textarea name="message" cols="30" className="p-3" rows="5" placeholder="Tell us about your project..." required></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="d-grid d-md-flex justify-content-md-center">
                                    <Button variant="btn" className="mb-3 px-5 py-2 me-md-2 mt-4 defaultButton" type="submit">
                                        <span /> Send Message
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Stack>
                </Row>
            </Container>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="py-5 my-3 g-4 justify-content-center">
                        <div className="col-sm-6 col-lg-4 col-12">
                            <div className="d-flex p-5 flex-column align-items-center py-3 bg-white h-100">
                                <Image src={Image01} width="100" height="100" className="mt-3" alt="Card Image" loading="lazy" />
                                <div>
                                    <h5 className="card-title my-3 text-center">Case studies</h5>
                                    <p className="defaultText text-center">Our work speaks volumes for us. Read in depth about how we have helped countless partners with their products and helped them take flight.</p>
                                </div>
                                <Button type="button" variant="btn" className="link-primary d-none">
                                    View Our Case Study
                                </Button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-12 ">
                            <div className="d-flex p-5 flex-column align-items-center py-3 bg-white h-100">
                                <Image src={Image02} width="100" height="100" className="mt-3" alt="Card Image" loading="lazy" />
                                <div>
                                    <h5 className="card-title my-3 text-center">FAQ's</h5>
                                    <p className="defaultText text-center">Read the answers to some of the most commonly asked questions about our services and processes.</p>
                                </div>
                                <Button type="button" variant="btn" className="link-primary d-none">
                                    View Our FAQ's
                                </Button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-12">
                            <div className="d-flex p-5 flex-column align-items-center py-3 bg-white h-100">
                                <Image src={Image03} width="100" height="100" className="mt-3" alt="Card Image" loading="lazy" />
                                <div>
                                    <h5 className="card-title my-3 text-center">Insights</h5>
                                    <p className="defaultText text-center">Understand our processes and our thought on the latest in the industries we work with through our blog.</p>
                                </div>
                                <Button type="button" variant="btn" className="link-primary d-none">
                                    View All
                                </Button>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <HeroSection />
        </Stack>
    )
}

export default ContactUs
