import { React, useState, useEffect } from 'react'
import { Container, Image, Row, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Firebase from '../../assets/master/images/card-icons/technologies/Firebase.webp'
import TypeScript from '../../assets/master/images/card-icons/technologies/TypeScript.webp'
import Vuejs from '../../assets/master/images/card-icons/technologies/Vuejs.webp'
import Reactjs from '../../assets/master/images/card-icons/technologies/Reactjs.svg'
import Figma from '../../assets/master/images/card-icons/technologies/Figma.webp'
import AdobeXD from '../../assets/master/images/card-icons/technologies/AdobeXD.webp'
import Laravel from '../../assets/master/images/card-icons/technologies/Laravel.webp'
import Nodejsd from '../../assets/master/images/card-icons/technologies/Nodejsd.webp'
import ASPNET from '../../assets/master/images/card-icons/technologies/ASPNET.webp'
import Flutter from '../../assets/master/images/card-icons/technologies/Flutter.svg'
import Java from '../../assets/master/images/card-icons/technologies/Java.webp'
import Angular from '../../assets/master/images/card-icons/technologies/Angular.webp'
import AWS from '../../assets/master/images/card-icons/technologies/AWS.webp'
import Illustrator from '../../assets/master/images/card-icons/technologies/Illustrator.webp'
import Jira from '../../assets/master/images/card-icons/technologies/Jira.webp'
import Selenium from '../../assets/master/images/card-icons/technologies/Selenium.webp'

function Technologies() {
    function moveTop() {
        document.documentElement.scrollTop = 0
    }
    const cardsData = [
        {
            title: 'Firebase',
            color: '#ffcb2c',
            image: Firebase,
            category: ['all', 'web', 'backend'],
        },
        {
            title: 'TypeScript',
            color: '#3178c6',
            image: TypeScript,
            category: ['all', 'web', 'frontend'],
        },
        {
            title: 'Vuejs',
            color: '#41b883',
            image: Vuejs,
            category: ['all', 'web', 'frontend'],
        },
        {
            title: 'React Native',
            color: '#00d8ff',
            image: Reactjs,
            category: ['all', 'mobile'],
        },
        {
            title: 'Figma',
            color: '#03d082',
            image: Figma,
            category: ['all', 'design'],
        },
        {
            title: 'Adobe XD',
            color: '#ff61f6',
            image: AdobeXD,
            category: ['all', 'design'],
        },
        {
            title: 'React Js',
            color: '#1696ec',
            image: Reactjs,
            category: ['all', 'web', 'frontend'],
        },
        {
            title: 'Laravel',
            color: '#ff2d20',
            image: Laravel,
            category: ['all', 'backend'],
        },
        {
            title: 'Node.Js',
            color: '#539f43',
            image: Nodejsd,
            category: ['all', 'backend', 'frontend'],
        },
        {
            title: 'ASP .NET',
            color: '#0388c9',
            image: ASPNET,
            category: ['all', 'web', 'backend'],
        },
        {
            title: 'Flutter',
            color: '#67b7f7',
            image: Flutter,
            category: ['all', 'mobile'],
        },
        {
            title: 'Java',
            color: '#db380e',
            image: Java,
            category: ['all', 'mobile'],
        },
        {
            title: 'Angular',
            color: '#c3002f',
            image: Angular,
            category: ['all', 'frontend'],
        },
        {
            title: 'AWS',
            color: '#ff9900',
            image: AWS,
            category: ['all', 'backend'],
        },
        {
            title: 'Illustrator',
            color: '#fa9700',
            image: Illustrator,
            category: ['all', 'design'],
        },
        {
            title: 'Jira',
            color: '#0684ff',
            image: Jira,
            category: ['all', 'testing'],
        },
        {
            title: 'Selenium',
            color: '#43b02a',
            image: Selenium,
            category: ['all', 'testing'],
        },
    ]
    const [filter, setFilter] = useState('all')
    const [projects, setProjects] = useState([])
    useEffect(() => {
        setProjects()
    }, [])
    useEffect(() => {
        setProjects([])
        const filtered = cardsData.map((p) => ({ ...p, filtered: p.category.includes(filter) }))
        setProjects(filtered)
    }, [filter])
    return (
        <Stack style={{ backgroundColor: `#f6f7ff` }}>
            <Container fluid="xl mt-5">
                <Row className="align-items-center py-4">
                    <div className="col-lg-6">
                        <h1 className="display-6 fw-bold lh-1 mb-3 mainColor">Tech We Love</h1>
                        <div className="d-md-grid gap-2 d-md-flex justify-content-md-start d-none">
                            <Link type="button" className="btn  mb-4 px-4 me-md-2 mt-2 defaultButton" onClick={moveTop}>
                                <span></span>Learn More
                            </Link>
                        </div>
                    </div>
                    <div className="col col-lg-6">
                        <p className="lead">Our open-source contributions and products are designed with you in mind, either out of a love of experiments or out of a need to find solutions to development related issues.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start d-md-none">
                            <Link type="button" className="btn  mb-4 px-4 me-md-2 mt-2 defaultButton" onClick={moveTop}>
                                <span></span>Learn More
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 technologiesNav">
                        <ul className="nav justify-content-center py-3">
                            <li active={filter === 'all'} className={`nav-item ${filter === 'all' ? 'active' : ''}`} onClick={() => setFilter('all')}>
                                TOP
                            </li>
                            <li className={`nav-item ${filter === 'web' ? 'active' : ''}`} onClick={() => setFilter('web')}>
                                WEB
                            </li>
                            <li className={`nav-item ${filter === 'mobile' ? 'active' : ''}`} onClick={() => setFilter('mobile')}>
                                MOBILE
                            </li>
                            <li className={`nav-item ${filter === 'backend' ? 'active' : ''}`} onClick={() => setFilter('backend')}>
                                BACK-END
                            </li>
                            <li className={`nav-item ${filter === 'frontend' ? 'active' : ''}`} onClick={() => setFilter('frontend')}>
                                FRONT-END
                            </li>
                            <li className={`nav-item ${filter === 'design' ? 'active' : ''}`} onClick={() => setFilter('design')}>
                                DESIGN
                            </li>
                            <li className={`nav-item ${filter === 'testing' ? 'active' : ''}`} onClick={() => setFilter('testing')}>
                                TESTING
                            </li>
                        </ul>
                    </div>
                    <Stack className="col-12 my-3">
                        <Row className="g-4">
                            {projects.map((item) =>
                                item.filtered === true ? (
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                        <div className="gap-3 d-flex justify-content-center align-items-center position-relative technologies p-3">
                                            <span className="position-absolute" style={{ borderRight: item.color + ` 35px solid` }} />
                                            <Image src={item.image} alt="Card Image" loading="lazy"  />
                                            <h5 className="card-title">{item.title}</h5>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )
                            )}
                        </Row>
                    </Stack>
                </Row>
            </Container>
        </Stack>
    )
}

export default Technologies
