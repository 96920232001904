import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/master/css/components/PortFolioCard.css'
import { Row, Container, Image, Stack } from 'react-bootstrap'

import GiantCart from '../../assets/master/images/ourWork/GiantCart.webp'
import HajiBaba from '../../assets/master/images/ourWork/HajiBaba.webp'
import JusATutor from '../../assets/master/images/ourWork/JusATutor.webp'
import PakVisa from '../../assets/master/images/ourWork/PakVisa.webp'
import Shambapro from '../../assets/master/images/ourWork/Shambapro.webp'
import Survey from '../../assets/master/images/ourWork/Survey.webp'
import AstroHouz from '../../assets/master/images/ourWork/AstroHouz.webp'
import Afiye from '../../assets/master/images/ourWork/Afiye.webp'
import Clinic from '../../assets/master/images/ourWork/Clinic.webp'
import Diamond from '../../assets/master/images/ourWork/Diamond.webp'

const cardsData = [
    {
        category: ['all', 'web', 'backend', 'frontend', 'design'],
        title: 'Just A Tutor',
        description: 'Jus A Tutor is an online tutoring Platform Whare High Qualified Mathematics Teacher Can Enter Questions and with different level and Teacher have only access to the specific level. Also, Teacher can update and delete...',
        image: JusATutor,
        path: 'jus-a-tutor',
    },
    {
        category: ['all', 'web', 'backend', 'frontend', 'design'],
        title: 'PakVisa',
        description: 'PakVisa Is a Website of Real Estate where Users can post their ads about buying and selling properties. User can SignUP their account and then Login to their account for post their ads. Users can feature their ads...',
        image: PakVisa,
        path: 'pak-visa',
    },
    {
        category: ['all', 'mobile', 'backend', 'frontend', 'design'],
        title: 'Haji Baba',
        description: 'Haji Baba is a United Kingdom Base Halal Meat Company. It has 70+ store in United Kingdom and we are working with since October 2021. We have developed its mobile application for their customers and for their stores...',
        image: HajiBaba,
        path: 'haji-baba',
    },
    {
        category: ['all', 'web', 'backend', 'frontend'],
        title: 'GiantCart',
        description: 'GiantCart is an Ecommerce Website where customers and Vendors can register their accounts. Vendor can upload their products and each product upload with 3 prices (Regular, Bulk and B2B). Vendors can also update and...',
        image: GiantCart,
        path: 'GiantCart',
    },
    {
        category: ['all', 'mobile', 'backend', 'frontend', 'design'],
        title: 'Shambapro',
        description: 'We have been working with Shambapro app for 2 Years. Shambapro enables small scale farmers in Africa access suitable agriculture financing without traditional collateral and other value chain services like ready...',
        image: Shambapro,
        path: 'shamba-pro',
    },
    {
        category: ['all', 'mobile', 'backend', 'frontend', 'design'],
        title: 'Survey',
        description: 'Survey App is an App for the pollster and managers. Which will serve the purpose of getting done different surveys with the help of a mobile application. The pollster will have assigned surveys to them and where...',
        image: Survey,
        path: 'survey-mobile-app',
    },
    {
        category: ['all', 'web', 'backend', 'frontend', 'design'],
        title: 'Afiye Website',
        description: 'Afiye is a real estate and rental marketplace committed to empowering our customers with relevant information about prospective homes and connecting them with the best local professionals who can help. It starts...',
        image: Afiye,
        path: 'afiye',
    },
    {
        category: ['all', 'ERPs'],
        title: 'Clinic Management System',
        description: 'Our Clinic Management system is Deployed on 50+ Clinic all over Pakistan, in this system we are handling patients, patients’ appointments, patients treatment as per the appointment, appointment charges, appointments...',
        image: Clinic,
        path: 'clinic-management-system',
    },
    {
        category: ['all', 'web', 'backend', 'frontend', 'design'],
        title: 'AstroHouz',
        description: 'AstroHouz is an online web Platform that works all around the world. We have solved problem for people who need astrologist’s services so they can order astrology services online. Same for the Astrologist they can sale... ',
        image: AstroHouz,
        path: 'astro-houz',
    },
    {
        category: ['all', 'mobile', 'backend', 'frontend', 'design'],
        title: 'J Diamond Mobile App',
        description: 'JDiamond is the first Qatari online retail shop specializing in essential products for Men with a vision of helping men to have their essential products by the most trustworthy online platform and the most appropriate customer...',
        image: Diamond,
        path: 'j-diamond',
    },
]

function PortFolioCard() {
    function moveTop() {
        document.documentElement.scrollTop = 0
    }

    const [filter, setFilter] = useState('all')
    const [projects, setProjects] = useState([])
    useEffect(() => {
        setProjects()
    }, [])
    useEffect(() => {
        setProjects([])
        const filtered = cardsData.map((p) => ({ ...p, filtered: p.category.includes(filter) }))
        setProjects(filtered)
    }, [filter])

    return (
        <Container fluid="xl">
            <Row>
                <div className="col-12 technologiesNav mb-4">
                    <ul className="nav justify-content-center py-3">
                        <li active={filter === 'all'} className={`nav-item ${filter === 'all' ? 'active' : ''}`} onClick={() => setFilter('all')}>
                            TOP
                        </li>
                        <li className={`nav-item ${filter === 'web' ? 'active' : ''}`} onClick={() => setFilter('web')}>
                            WEB
                        </li>
                        <li className={`nav-item ${filter === 'mobile' ? 'active' : ''}`} onClick={() => setFilter('mobile')}>
                            MOBILE
                        </li>
                        <li className={`nav-item ${filter === 'ERPs' ? 'active' : ''}`} onClick={() => setFilter('ERPs')}>
                            ERPs
                        </li>
                        <li className={`nav-item ${filter === 'backend' ? 'active' : ''}`} onClick={() => setFilter('backend')}>
                            BACK-END
                        </li>
                        <li className={`nav-item ${filter === 'frontend' ? 'active' : ''}`} onClick={() => setFilter('frontend')}>
                            FRONT-END
                        </li>
                        <li className={`nav-item ${filter === 'design' ? 'active' : ''}`} onClick={() => setFilter('design')}>
                            DESIGN
                        </li>
                    </ul>
                </div>
                <div className="col-12 mb-5">
                    <Row className="g-5">
                        {projects.map((item) =>
                            item.filtered === true ? (
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="p-4 d-flex flex-column portFolioCard h-100">
                                        <div className="col-12 text-center">
                                            <Image className="w-100" src={item.image} alt="card Image" loading="lazy" />
                                        </div>
                                        <Stack className="col-12 d-flex flex-column justify-content-between text-center position-relative">
                                            <h6 className="card-title text-center p-3 position-absolute">{item.title}</h6>
                                            <p className="defaultText">{item.description}</p>
                                            <div className="d-grid d-md-flex justify-content-center">
                                                <Link to={'/our-work/' + item.path} className="btn px-4 me-md-2 mt-2 defaultButton" onClick={moveTop}>
                                                    <span />
                                                    Read More
                                                </Link>
                                            </div>
                                        </Stack>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )
                        )}
                    </Row>
                </div>
            </Row>
        </Container>
    )
}

export default PortFolioCard
