import React from 'react'
import { Row, Container, Stack } from 'react-bootstrap'
import HeroSection from './components/HeroSection'
import Background from '../assets/master/images/backgrounds/product-banner.webp'
import PortFolioCard from './components/PortFolioCard'
function OurWork() {
    document.title = 'OwaSoft - Our Work'
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
    }
    return (
        <Stack className="main">
            <Container fluid className="pt-5" style={MainBanner}>
                <Container fluid="xl">
                    <Row className="col-12 col-md-8 flex-column align-content-center justify-content-center my-5  py-md-5">
                        <h1 className="display-3 text-white fw-bold text-uppercase">our work</h1>
                        <h6 className="my-3 text-white lead">Dive deep into our processes and how we helped our clients achieve their goals and dreams through the work that we do. Our case studies are a detailed walkthrough of how we solve problems and create transforming digital solutions on the daily.</h6>
                        <h6 className="m-0 text-white lead">
                            turning ideas into <span className="mainColor"> web applications </span>
                        </h6>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="pt-5" style={{ backgroundColor: `#f6f7ff` }}>
                <PortFolioCard />
            </Container>
            <HeroSection />
        </Stack>
    )
}

export default OurWork
