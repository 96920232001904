import React from 'react'

import { Stack, Container, Row, Image } from 'react-bootstrap'

import HeroImage from '../../assets/master/images/cover/hero-5.svg'
import Background from '../../assets/master/images/backgrounds/bg-5.webp'
import { Link } from 'react-router-dom'

function HeroSection() {
    const HeroSectionBg = {
        backgroundImage: `url(${Background})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    }

    function moveTop() {
        document.documentElement.scrollTop = 0
    }
    return (
        <Stack style={HeroSectionBg}>
            <Container fluid="xl mt-4">
                <Row className="flex-md-row-reverse pt-md-5">
                    <div className="col-12 col-md-6">
                        <Image className="w-100" src={HeroImage} alt="card image" loading="lazy" />
                    </div>
                    <div className="col-12 col-md-6 mt-4 mt-md-0">
                        <h2 className="fw-bold lh-1 mb-3 mainColor">Let's Design & Develop something amazing together!</h2>
                        <p className="lead defaultText">You are at the forefront of everything we do. An unforgettable experience and first- rate service await</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <Link to={window.location.pathname === '/contact-us' ? '' : '../contact-us'} type="button" className="btn px-4 me-md-2 my-2 defaultButton my-4" onClick={moveTop}>
                                <span />
                                Let's Talk
                            </Link>
                        </div>
                    </div>
                </Row>
            </Container>
        </Stack>
    )
}

export default HeroSection
