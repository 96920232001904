import React from 'react'
import { Row, Container, Stack, Image, Card } from 'react-bootstrap'
import '../../assets/master/css/caseStudyDetails.css'

import Background from '../../assets/master/images/backgrounds/product-banner.webp'
import Logo from '../../assets/master/images/card-icons/ClinicManagementSystemLogo.webp'
import BannerImage from '../../assets/master/images/cover/ClinicManagementSystemCover.webp'
import HeroSection from '../components/HeroSection'
import backgroundImage4 from '../../assets/master/images/backgrounds/bg-4.webp'
import heroImage4 from '../../assets/master/images/cover/hero-6.webp'
import backgroundImage5 from '../../assets/master/images/backgrounds/bg-8.webp'

import Background001 from '../../assets/master/images/backgrounds/bg-6.webp'
import Icon1 from '../../assets/master/images/card-icons/icon-30.webp'
import Icon2 from '../../assets/master/images/card-icons/icon-31.webp'
import Icon3 from '../../assets/master/images/card-icons/icon-32.webp'
import Icon4 from '../../assets/master/images/card-icons/icon-33.webp'
import Icon5 from '../../assets/master/images/card-icons/icon-34.webp'

import caseStudyCardImage1 from '../../assets/master/images/card-icons/icon-35.png'
import caseStudyCardImage2 from '../../assets/master/images/card-icons/icon-36.png'
import caseStudyCardImage3 from '../../assets/master/images/card-icons/icon-37.png'
import caseStudyCardImage4 from '../../assets/master/images/card-icons/icon-38.png'
import caseStudyCardImage5 from '../../assets/master/images/card-icons/icon-39.png'
import caseStudyCardImage6 from '../../assets/master/images/card-icons/icon-40.png'

import problemsImage from '../../assets/master/images/cover/Group26654@3x.png'

import RequirementsImg1 from '../../assets/master/images/card-icons/icon-40.webp'
import RequirementsImg2 from '../../assets/master/images/card-icons/icon-41.webp'
import RequirementsImg3 from '../../assets/master/images/card-icons/icon-42.webp'

import developmentImage from '../../assets/master/images/cover/Group26655@3x.png'
import challengeImage from '../../assets/master/images/cover/Group19809@3x.png'
import viewImage from '../../assets/master/images/cover/Group26784@3x.png'
import liBulletsWhite from '../../assets/master/images/listIconWhite.webp'
function ClinicManagementSystem() {
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
    }
    const Background01 = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background001})`,
    }
    const background4 = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage4})`,
    }
    const background5 = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage5})`,
    }
    const liBulletWhite = {
        listStyleImage: `url( ${liBulletsWhite} )`,
    }
    document.title = 'GiantCart | OwaSoft'
    return (
        <Stack className="main">
            <Container fluid className="pt-5" style={MainBanner}>
                <Container fluid="xl">
                    <Row className="position-relative my-5 py-3 py-md-5">
                        <div className="col-12 ">
                            <Image className="mb-2 img-fluid" width="100" src={Logo} loading="lazy" alt="card Image" />
                            <h1 className="display-4 text-white fw-bold">Clinic Management System</h1>
                        </div>
                        <div className="col-6 position-absolute float-end end-0 MainBannerImage d-none d-lg-block">
                            <Image src={BannerImage} loading="lazy" alt="card Image" className="w-100" />
                        </div>
                    </Row>
                </Container>
            </Container>
            <Stack style={Background01} className="py-5">
                <Container fluid="xl">
                    <Row className="pt-2 pt-lg-5 mt-lg-5 position-relative justify-content-center justify-content-md-between flex-wap align-items-start">
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon1} />
                            <Card.Body className="text-center">
                                <Card.Title>Timeline</Card.Title>
                                <Card.Text className="defaultText">6 Months</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon2} />
                            <Card.Body className="text-center">
                                <Card.Title>Industry</Card.Title>
                                <Card.Text className="defaultText">HealthCare</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon3} />
                            <Card.Body className="text-center">
                                <Card.Title>Location</Card.Title>
                                <Card.Text className="defaultText">Pakistan </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon4} />
                            <Card.Body className="text-center">
                                <Card.Title>Project</Card.Title>
                                <Card.Text className="defaultText">Development</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon5} />
                            <Card.Body className="text-center">
                                <Card.Title>Year</Card.Title>
                                <Card.Text className="defaultText">2021</Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </Stack>
            <Container fluid="xl">
                <Row className="pt-2 px-4 px-lg-0 justify-content-center justify-content-md-around flex-wap align-items-start">
                    <div className="bg-transparent py-4 mx-3 case-study-card-1 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage1} />
                        <Card.Body>
                            <Card.Title className="my-3">Strategy</Card.Title>
                            <Card.Text className="defaultText">In this project, we joined hands with one of the biggest Doctor of Islamabad to reduce their Paperwork and Create and online platform where they can manage their treatment, patients, and their sales. But we have decided to hold all copyrights and will use this system as an Owasoft Product.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-2 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage2} />
                        <Card.Body>
                            <Card.Title className="my-3">Analysis Planning</Card.Title>
                            <Card.Text className="defaultText">Considering the client wanted a fast and efficient platform for their system, we decided to use Metronic theme along with ASP .NET Core as the desired technologies to work with.Considering the client wanted a fast and efficient Website for their Idea, we decided to use Angular JS along with PHP Laravel as the desired technologies to work with.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-3 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage3} />
                        <Card.Body>
                            <Card.Title className="my-3">UI/UX Design</Card.Title>
                            <Card.Text className="defaultText">Considering we have use Metronic theme for this system and we have Improve this theme as UX aspect and approve my client and test for UX as well.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-4 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage4} />
                        <Card.Body>
                            <Card.Title className="my-3">Development</Card.Title>
                            <Card.Text className="defaultText">During the development phase, our team worked on Metronic theme integration and improvement. And then develop its backend according to the client requirements in ASP .NET.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-5 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage5} />
                        <Card.Body>
                            <Card.Title className="my-3">Testing</Card.Title>
                            <Card.Text className="defaultText">Testing was essential to ensure that the web app was up to the mark because of which we roped in one QA analyst who conducted manual testing for the project.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-6 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage6} />
                        <Card.Body>
                            <Card.Title className="my-3">Delivery</Card.Title>
                            <Card.Text className="defaultText">The work on the project is finalize in October 2021, and the client is extremely happy with our services that has been made and how the System is turning out to be.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                </Row>
            </Container>
            <Stack style={background4} className="mt-4">
                <Container fluid="xl">
                    <Row className="flex-lg-row-reverse py-5">
                        <Stack className="col col-lg-5">
                            <Image className="w-100" src={heroImage4} alt="card Image" loading="lazy" />
                        </Stack>
                        <Stack className="col-lg-7 text-white mt-5 mt-lg-0">
                            <h1 className="display-6 fw-bold">About the Client</h1>
                            <p className="lead pt-3">Our client is a one of the best Doctor in Islamabad Pakistan. He wants to reduce their Paperwork and Create and online platform where they can manage their treatment, patients, and their sales. But we have decided to hold all copyrights and will use this system as an Owasoft Product. Now this system is installed in 50+ clinic in Pakistan.</p>
                        </Stack>
                    </Row>
                </Container>
            </Stack>
            <Stack>
                <Container fluid="xl">
                    <Row className="pt-5">
                        <div>
                            <div className=" col-md-4 float-start ms-0 ms-lg-5 me-lg-5">
                                <Image className="w-100" src={problemsImage} alt="card Image" loading="lazy" />
                            </div>
                            <h1 className="display-6 fw-bold mainColor mt-5 mt-lg-0">The Problem</h1>
                            <p className="lead">The Client has a Clinic in Pakistan. He wants to reduce their Paperwork and Create and online platform where they can manage their treatment, patients, and their sales. We have developed an ERP system for his clinic.</p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="py-5">
                        <h1 className="display-6 fw-bold my-3 mainColor">Team On-Call</h1>
                        <p className="lead defaultText">After understanding the requirements that the project would consist of, we roped in one front-end Developer, ASP .NET Core Developer, and a project manager to handle the project as directed by the client. Considering the project is quite different from what we usually do at Owasoft, the team had to spend quite some time catching the drift of what the client wanted and coming up with ideas that matched their requisites.</p>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background4}>
                <Container fluid="xl">
                    <Row className="py-5 justify-content-around">
                        <h1 className="display-6 fw-bold mb-5 text-white text-center">Requirements</h1>
                        <Card className="bg-transparent border-0 m-3 d col-md-3">
                            <Card.Img loading="lazy" variant="top" className="w-50 m-auto" src={RequirementsImg3} />
                            <Card.Body className="text-center">
                                <Card.Text className="text-white"> Require an online system where clients can manage their clinic.</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 m-3 d col-md-3">
                            <Card.Img loading="lazy" variant="top" className="w-50 m-auto" src={RequirementsImg2} />
                            <Card.Body className="text-center">
                                <Card.Text className="text-white">mplement User Modules and Accesses Privileges</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 m-3 d col-md-3">
                            <Card.Img loading="lazy" variant="top" className="w-50 m-auto" src={RequirementsImg1} />
                            <Card.Body className="text-center">
                                <Card.Text className="text-white"> Implement dental treatment section.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </Stack>
            <Stack>
                <Container fluid="xl">
                    <Row className="pt-5">
                        <div>
                            <div className=" col-md-4 float-end ms-0 ms-lg-5">
                                <Image className="w-100" src={developmentImage} alt="card Image" loading="lazy" />
                            </div>
                            <h1 className="display-6 fw-bold mainColor mt-4 mt-lg-0">Development</h1>
                            <p className="lead">After the team was introduced to the client, we spent the initial few days brainstorming and researching how things need to be done based on the requirements and planning the way forward. After that start integration of Metronic theme and do improvement.</p>
                            <p className="lead">Then We will start backend development when the theme is complete integration of them. We have Developed backend in ASP .NET core 5.</p>
                            <p className="lead">After integration and completing functionalities, we will start QA on the system and write documentation for it and fix any kind of issue related to the development and UX.</p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="py-5 mt-5">
                        <div>
                            <div className=" col-md-6 float-start me-5 mb-4">
                                <Image className="w-100" src={challengeImage} alt="card image" loading="lazy" />
                            </div>
                            <h1 className="display-6 fw-bold mainColor mt-lg-0">Overcoming Challenges</h1>
                            <p className="lead">One of the major challenge for us in the project is to implement dental treatment section. Our team handled this by holding daily meetings along with standup calls with the client where we discussed what needs to be done to take the project forward.</p>
                            <p className="lead">However, our team conducted extensive studies and researched the niche industry and the client's competitors which helped them to come up with creative ideas. We coordinated with them proactively to understand what they required and took the necessary feedback and implemented the changes one page at a time before moving on to the next one.</p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background4}>
                <Container fluid="xl">
                    <Row className="flex-lg-row-reverse py-5">
                        <Stack className="col col-lg-5 mb-5">
                            <Image className="w-100" src={viewImage} alt="card image" loading="lazy" />
                        </Stack>
                        <Stack className="col-lg-6 text-white">
                            <h1 className="display-6 fw-bold mb-3">In bird's eye view</h1>
                            <ul style={liBulletWhite}>
                                <li className="lead ps-1">VPS Server was used for server deployment.</li>
                                <li className="lead ps-1">The tool used to automate the testing process was Gitlab.</li>
                                <li className="lead ps-1">Slack was the tool of choice for project management.</li>
                            </ul>
                        </Stack>
                        <h1 className="display-6 fw-bold text-white mb-3 mt-4">Final Impression</h1>
                        <p className="lead text-white">The project is completed with the development and implementation at the final deadline. The Web application has currently Running in real-time world and our partner’s satisfaction with the progress is exemplified. We are delighted to have collaborated with our client for expansion, we foresee a long-term visibility of the project which we hope to execute in collaboration with our partner.</p>
                    </Row>
                </Container>
            </Stack>
            <HeroSection />
        </Stack>
    )
}

export default ClinicManagementSystem
