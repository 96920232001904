import React from 'react'
import { Row, Container, Stack, Image } from 'react-bootstrap'

import Background from '../../assets/master/images/backgrounds/product-banner.webp'
import liBulletsColor from '../../assets/master/images/list.png'
import backgroundImage5 from '../../assets/master/images/backgrounds/bg3.webp'
import backgroundImage4 from '../../assets/master/images/backgrounds/bg-4.webp'
import uxResearch from '../../assets/master/images/cover/Group.webp'
import designImage from '../../assets/master/images/cover/design.webp'
import logoBrandingImage from '../../assets/master/images/cover/logoBrandingImage.webp'
import HeroSection from './../components/HeroSection'

import xdIcon1 from '../../assets/master/images/card-icons/icon-45.webp'
import xdIcon2 from '../../assets/master/images/card-icons/icon-46.webp'
import xdIcon3 from '../../assets/master/images/card-icons/icon-47.webp'
import xdIcon4 from '../../assets/master/images/card-icons/icon-48.webp'
import xdIcon5 from '../../assets/master/images/card-icons/icon-49.webp'
import xdIcon6 from '../../assets/master/images/card-icons/icon-50.webp'
import xdIcon7 from '../../assets/master/images/card-icons/icon-51.webp'
import xdIcon8 from '../../assets/master/images/card-icons/icon-52.webp'
import xdIcon9 from '../../assets/master/images/card-icons/icon-53.webp'
import xdIcon10 from '../../assets/master/images/card-icons/icon-54.webp'
import xdIcon11 from '../../assets/master/images/card-icons/icon-55.webp'

import designIcon1 from '../../assets/master/images/card-icons/icon-56.webp'
import designIcon2 from '../../assets/master/images/card-icons/icon-56.webp'

import uxIcon1 from '../../assets/master/images/card-icons/sketch@3x.webp'
import uxIcon2 from '../../assets/master/images/card-icons/Adobe_XD_CC_icon.svg@3x.webp'
import uxIcon3 from '../../assets/master/images/card-icons/invision-logo@3x.webp'
import uxIcon4 from '../../assets/master/images/card-icons/Adobe_After_Effects_CC_icon.svg@3x.webp'
import uxIcon5 from '../../assets/master/images/card-icons/adobe-illustrator-logo-775FAF240B-seeklogo.com@3x.webp'
import uxIcon6 from '../../assets/master/images/card-icons/2cfe978f6f2a54819103377f91e7842a@3x.webp'
import uxIcon7 from '../../assets/master/images/card-icons/62c6bc3deee9410fe137d920@3x.webp'
import LatestProducts from './../components/LatestProducts'

function UiUx() {
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
    }
    const liBullets = {
        listStyleImage: `url( ${liBulletsColor} )`,
    }
    const background4 = {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage4})`,
    }
    const background5 = {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage5})`,
    }
    const uxCard = {
        backgroundColor: `#f6f7ff`,
        aspectRatio: `1`,
        borderRadius: `10px`,
    }
    return (
        <Stack className="main">
            <Stack className="pt-5" style={MainBanner}>
                <Container fluid="xl">
                    <Row className="col-md-8 flex-column align-content-center justify-content-center my-5  py-md-5">
                        <h1 className="display-3 text-white fw-bold text-uppercase">UI/ux design</h1>
                        <h6 className="my-3 text-white lead">Owasoft’s exclusive UI/UX Designing Services are the best way to turn ideas into products that work. Before you share your idea, we read your mind!</h6>
                        <h6 className="my-3 text-white lead">
                            Services - <span className="mainColor">UI/UX Design </span>
                        </h6>
                    </Row>
                </Container>
            </Stack>
            <Container fluid="xl">
                <Row className="py-5">
                    <h1 className="display-6 fw-bold lh-1 mb-3 mainColor">How we started with UX Design</h1>
                    <div className="col-md-6">
                        <ul style={liBullets}>
                            <li className="lead ps-1 mb-3 lh-sm defaultText">Starting to configuration to address the absence of creativity in plan for some advancement projects.</li>
                            <li className="lead ps-1 mb-3 defaultText">At that point, we realized that we had always had a flair for design; However, once we realized that we could do more with this space, the realization became more concrete.</li>
                            <li className="lead ps-1 mb-3 defaultText">Another important reason was that customers always appreciated our website, which we designed.</li>
                            <li className="lead ps-1 mb-3 defaultText">Because of this, what started out as minor adjustments to client designs has grown into a fully operational department of our product studio with solid design principles and capabilities!</li>
                        </ul>
                    </div>
                    <div className="col-md-6 ps-md-5">
                        <Row className="g-4 justify-content-center">
                            <div className="col-lg-3 col-md-4 col-sm-3 col-6">
                                <div className=" d-flex flex-column justify-content-center align-items-center" style={uxCard}>
                                    <Image src={uxIcon1} alt="card image" loading="lazy" width="50" hight="50" />
                                    <h6 className="card-title mt-3">Sketch</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-3 col-6">
                                <div className=" d-flex flex-column justify-content-center align-items-center" style={uxCard}>
                                    <Image src={uxIcon2} alt="card image" loading="lazy" width="50" hight="50" />
                                    <h6 className="card-title mt-3">Xd</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-3 col-6">
                                <div className=" d-flex flex-column justify-content-center align-items-center" style={uxCard}>
                                    <Image src={uxIcon3} alt="card image" loading="lazy" width="50" hight="50" />
                                    <h6 className="card-title mt-3">Invision</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-3 col-6">
                                <div className=" d-flex flex-column justify-content-center align-items-center" style={uxCard}>
                                    <Image src={uxIcon4} alt="card image" loading="lazy" width="50" hight="50" />
                                    <h6 className="card-title mt-3">After Effects</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-3 col-6">
                                <div className=" d-flex flex-column justify-content-center align-items-center" style={uxCard}>
                                    <Image src={uxIcon5} alt="card image" loading="lazy" width="50" hight="50" />
                                    <h6 className="card-title mt-3">Illustrator</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-3 col-6">
                                <div className=" d-flex flex-column justify-content-center align-items-center" style={uxCard}>
                                    <Image src={uxIcon6} alt="card image" loading="lazy" width="50" hight="50" />
                                    <h6 className="card-title mt-3">Photoshop</h6>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-3 col-6">
                                <div className=" d-flex flex-column justify-content-center align-items-center" style={uxCard}>
                                    <Image src={uxIcon7} alt="card image" loading="lazy" width="50" hight="50" />
                                    <h6 className="card-title mt-3">Figma</h6>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row>
            </Container>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="pt-5 pb-0 pb-md-5 mt-0 mt-md-5 align-items-center">
                        <div className="col-12 col-md-6">
                            <h1 className="display-6 fw-bold my-3 mainColor">About the Client</h1>
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="lead defaultText">Innovation and research drives our simple design process to deliver future ready interfaces and tailored experiences for users.</p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background4}>
                <Container fluid="xl">
                    <Row className="py-5">
                        <Stack className="col col-lg-6 px-5 pb-md-0 pb-5 ">
                            <Image className="w-100" src={uxResearch} alt="card image" loading="lazy" />
                        </Stack>
                        <Stack className="col-lg-6 text-white">
                            <h1 className="display-6 fw-bold mb-3">UX Research</h1>
                            <p className="lead">A great user experience determines an app's success. Our research considers every app stakeholder and future trend.</p>
                            <Row>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon1} alt="card image" loading="lazy" width="40" height="40" />
                                    <p className="ps-3 mb-0">Project Overview</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon2} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Stakeholder Interview</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon3} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Initial Assumptions</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon4} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Competitive Analysis</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon5} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">User Interviews</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon6} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Brainstorming</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon7} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Wireframing</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon8} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Information Architecture</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon9} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Identifying pain-points</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon10} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Empathy Map</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon11} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">User Persona</p>
                                </div>
                            </Row>
                        </Stack>
                    </Row>
                </Container>
            </Stack>
            <Stack>
                <Container fluid="xl">
                    <Row className="py-md-5 flex-lg-row-reverse align-items-center">
                        <Stack className="col col-md-6 p-5">
                            <Image className="w-100" src={logoBrandingImage} alt="card image" loading="lazy" />
                        </Stack>
                        <div className="col-md-6">
                            <h1 className="display-6 fw-bold mb-3 mainColor">Logo & Branding</h1>
                            <p className="lead">Your brand is your uniqueness. We understand the voice and vision of your app to create a brand that speaks for you.</p>
                            <Row>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={designIcon1} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Logo</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={designIcon2} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Stationary</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={designIcon1} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Typography</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={designIcon1} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Brand Color</p>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="py-md-5 align-items-center">
                        <Stack className="col col-md-6 p-5">
                            <Image className="w-100" src={designImage} alt="card image" loading="lazy" />
                        </Stack>
                        <div className="col-md-6">
                            <h1 className="display-6 fw-bold mb-3 mainColor">Visual Design</h1>
                            <p className="lead">We Design screens that look Stunning from your Ideas and wireframes using the best design systems.</p>
                            <Row>
                                <div className="col-12 d-flex align-items-center mb-4">
                                    <Image src={designIcon1} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Visual Designs (UI)</p>
                                </div>
                                <div className="col-12 d-flex align-items-center mb-4">
                                    <Image src={designIcon2} alt="card image" width="40" height="40" loading="lazy" />
                                    <p className="ps-3 mb-0">Brand Guidelines/Design System</p>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <LatestProducts title="Our Work" description="Our work with our partners speaks for our experience and love for the industry. Check out how we have helped our clients achieve their dreams." />
            <HeroSection />
        </Stack>
    )
}

export default UiUx
