import React from 'react'
import { Row, Container, Stack, Image } from 'react-bootstrap'

import HeroSection from '../components/HeroSection'
import LatestProducts from '../components/LatestProducts'
import backgroundImage5 from '../../assets/master/images/backgrounds/bg-8.webp'
import xdIcon1 from '../../assets/master/images/card-icons/Group35788.svg'
import xdIcon2 from '../../assets/master/images/card-icons/Group35789.svg'
import xdIcon3 from '../../assets/master/images/card-icons/Group35790.svg'
import xdIcon4 from '../../assets/master/images/card-icons/Group35791.svg'
import xdIcon5 from '../../assets/master/images/card-icons/Group35792.svg'
import xdIcon6 from '../../assets/master/images/card-icons/Group35793.svg'

import componentImage from '../../assets/master/images/cover/Group35877@3x.webp'
import Background from '../../assets/master/images/backgrounds/product-banner.webp'
import backgroundImage4 from '../../assets/master/images/backgrounds/bg-4.webp'
import jsIntroImage from '../../assets/master/images/cover/Group35833x.webp'

function SolidJs() {
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
    }
    const background5 = {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage5})`,
    }
    const background4 = {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage4})`,
    }
    return (
        <Stack className="main">
            <Stack className="pt-5" style={MainBanner}>
                <Container fluid="xl">
                    <Row className="col-md-8 flex-column align-content-center justify-content-center my-5  py-md-5">
                        <h1 className="display-3 text-white fw-bold text-uppercase">Solid JS</h1>
                        <h6 className="my-3 text-white lead">Our dedicated team of Solid JavaScript developers is committed to designing and developing cutting-edge apps that improve user experience and increase customer outreach for organizations</h6>
                        <h6 className="my-3 text-white lead">
                            Services - <span className="mainColor">Solid Js </span>
                        </h6>
                    </Row>
                </Container>
            </Stack>
            <Container fluid="xl">
                <Row className="py-3 py-md-5 flex-md-row-reverse align-items-center">
                    <div className="col col-md-6">
                        <Image src={jsIntroImage} className="w-100" alt="Card Image" loading="lazy" />
                    </div>
                    <div className="col-md-6 pe-lg-5">
                        <h1 className="display-6 fw-bold mb-3 mainColor">What is SolidJS?</h1>
                        <p className="lead ">Solid JavaScript is a cutting-edge, open-source reactive framework that enables the development of smooth user interfaces. Its absence of a virtual DOM and single-render approach make it one of the fastest JavaScript frameworks currently available. Additionally, its reactive foundation allows for the creation of advanced features not found in other frameworks.</p>
                    </div>
                </Row>
            </Container>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="py-5 align-items-center">
                        <Stack className="col col-lg-6 px-5 pb-md-0 pb-5 ">
                            <Image src={componentImage} className="w-100" alt="car image" loading="lazy" />
                        </Stack>
                        <div className="col-lg-6">
                            <h1 className="display-6 fw-bold mb-3 mainColor">Components of SolidJS</h1>
                            <p className="lead">An ideal experience determines the success of an app. Our research considers future trends as well as every stakeholder of the app.</p>
                            <Row>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon1} width="40" height="40" alt="Card Image" loading="lazy" />
                                    <p className="ps-3 mb-0">L Reactive State</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon6} width="40" height="40" alt="Card Image" loading="lazy" />
                                    <p className="ps-3 mb-0"> Virtual DOM</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon2} width="40" height="40" alt="Card Image" loading="lazy" />
                                    <p className="ps-3 mb-0">Components</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon3} alt="Card Image" loading="lazy" width="40" height="40" />
                                    <p className="ps-3 mb-0"> Event System</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon4} alt="Card Image" loading="lazy" width="40" height="40" />
                                    <p className="ps-3 mb-0">Lifecycle Methods</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon5} alt="Card Image" loading="lazy" width="40" height="40" />
                                    <p className="ps-3 mb-0">Routing</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon5} alt="Card Image" loading="lazy" width="40" height="40" />
                                    <p className="ps-3 mb-0"> Server-Side Rendering (SSR)</p>
                                </div>
                                <div className="col-6 d-flex align-items-center mb-4">
                                    <Image src={xdIcon5} alt="Card Image" loading="lazy" width="40" height="40" />
                                    <p className="ps-3 mb-0">Plugins</p>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background4}>
                <Container fluid="xl">
                    <Row className="flex-lg-row-reverse py-5">
                        <Stack className="col-lg-6 text-white">
                            <h1 className="display-6 fw-bold mb-3">How did we start with SolidJS?</h1>
                            <p className="lead">We have adopted Solid JavaScript as a key component in our front-end technology stack. Our skilled Solid JavaScript developers have utilized their capabilities to deliver exceptional web applications to clients worldwide across various industries. Its ability to handle asynchronous data streams is a significant advantage that enables the creation of high-performance and efficient apps.</p>
                            <p className="lead">Our Solid JavaScript developers favor this technology not only for its high-performance but also for its functional component architecture that improves the end-user experience. As a premier Solid JavaScript app development company in USA and India, we prioritize utilizing the full potential of Solid JavaScript to create unparalleled applications that surpass industry standards</p>
                        </Stack>
                    </Row>
                </Container>
            </Stack>
            <LatestProducts title="Related Articles" description="Here are some interesting reads from the Owasoft community for you to understand SolidJS better" />
            <HeroSection />
        </Stack>
    )
}

export default SolidJs
