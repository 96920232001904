import React from 'react'
import { Row, Container } from 'react-bootstrap'
import PortFolioCard from './PortFolioCard'

function LatestProducts(props) {
    return (
        <Container fluid="xl">
            <Row className="py-5">
                <h1 className="display-6 fw-bold my-3 mainColor">{props.title}</h1>
                <p className="lead defaultText">{props.description}</p>
                <PortFolioCard />
            </Row>
        </Container>
    )
}

export default LatestProducts
