import React from 'react'
import { Stack, Container, Row, Image } from 'react-bootstrap'

import MainBannerBg from '../assets/master/images/backgrounds/main-banner.webp'
import backgroundImage2 from '../assets/master/images/backgrounds/bg3.webp'
import heroImage2 from '../assets/master/images/cover/hero-2.svg'
import liBulletsColor from '../assets/master/images/list.png'
import liBulletsWhite from '../assets/master/images/listIconWhite.webp'
import heroImage3 from '../assets/master/images/cover/hero-3.svg'
import backgroundImage4 from '../assets/master/images/backgrounds/bg-4.webp'
import heroImage4 from '../assets/master/images/cover/hero-4.svg'
import HeroSection from './components/HeroSection'
import VideoSection from './components/VideoSection'
import ServicesCards from './components/ServicesCards'
import Solutions from './components/Solutions'
import { Link } from 'react-router-dom'
import Brands from './components/Brands'
import Technologies from './components/Technologies'

function Home() {
    document.title = 'OwaSoft'
    document.getElementsByTagName('META')[2].content = 'this is software house.'
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${MainBannerBg})`,
    }

    const background2 = {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage2})`,
    }

    const liBullets = {
        listStyleImage: `url( ${liBulletsColor} )`,
    }
    const liBulletWhite = {
        listStyleImage: `url( ${liBulletsWhite} )`,
    }
    const background4 = {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage4})`,
    }

    function moveTop() {
        document.documentElement.scrollTop = 0
    }

    return (
        <Stack className="main">
            <Stack className="pt-5" style={MainBanner}>
                <Container fluid="xl" className="mt-3">
                    <Row className="mt-2">
                        <div className="col-md-8 col-12 d-flex flex-column align-content-center justify-content-center mt-3 py-5 py-md-5">
                            <h1 className="display-3 fw-bold mainColor text-uppercase">
                                Creativity <span className="text-white">&</span>
                            </h1>
                            <h1 className="display-3 fw-bold mainColor text-uppercase">Excellence</h1>
                            <h1 className="text-white mb-4 fw-bold">
                                Your Partner For <span className="mainColor">Innovations</span>.
                            </h1>
                            <p className="m-0 text-white">Design {'&'} Development Studio</p>
                            <p className="m-0 text-white">
                                turning ideas into <span className="mainColor move-text fw-bold"></span>
                            </p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <VideoSection />
            <Brands />
            <Stack style={background2}>
                <Container fluid="xl my-3">
                    <Row className="flex-md-row-reverse py-5 justify-content-center">
                        <div className="col col-lg-6 col-md-8">
                            <Image className="w-100" src={heroImage2} alt="Card Image" loading="lazy" />
                        </div>
                        <div className="col-lg-6 col-md-11 mt-3 mt-md-0">
                            <h2 className="fw-bold mb-3 mainColor mt-3">Allow us to manage your projects from start to finish.</h2>
                            <p className="lead">We are capable of creating your product completely from scratch.</p>
                            <ul style={liBullets}>
                                <li className="lead ps-1 mb-3 lh-sm defaultText">Join us for full transparency in the distribution, planning, designing, development, testing, and development of your product.</li>
                                <li className="lead ps-1 mb-3 defaultText">Pricing and a Plan based on Project Size </li>
                                <li className="lead ps-1 mb-3 defaultText">Works best for both small and large businesses</li>
                            </ul>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                <Link to={'contact-us'} type="button" className="btn px-4 me-md-2 mt-2 defaultButton" onClick={moveTop}>
                                    <span />
                                    Start Your Project
                                </Link>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack>
                <Container fluid="xl my-3">
                    <Row className="flex-lg-row py-md-5 justify-content-center">
                        <div className="col pt-4 col-lg-6 col-md-8">
                            <Image className="w-100" src={heroImage3} alt="Card Image" loading="lazy" />
                        </div>
                        <div className="col-lg-6 col-md-11 mt-3 mt-md-0">
                            <h2 className="fw-bold lh-1 mb-3 mainColor mt-3">Employ us as your devoted team.</h2>
                            <p className="lead">WA knowledgeable team will be sent to construct your product. </p>
                            <ul style={liBullets}>
                                <li className="lead lh-sm mb-3 defaultText">project-focused pricing and planning. .</li>
                                <li className="lead lh-sm mb-3 defaultText">Join us in planning, designing, developing, testing, and deploying your product with 100% transparency.</li>
                            </ul>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                <Link to={'contact-us'} type="button" className="btn mb-3 px-4 me-md-2 mt-2 defaultButton" onClick={moveTop}>
                                    <span />
                                    Know More
                                </Link>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background4}>
                <Container fluid="xl">
                    <Row className="flex-md-row-reverse py-5 justify-content-center">
                        <div className="col col-lg-6 col-md-8">
                            <Image className="w-100" src={heroImage4} alt="Card Image" loading="lazy" />
                        </div>
                        <div className="col-lg-6 text-white col-md-11 mt-3 mt-md-0">
                            <h2 className="fw-bold mb-3 mt-3">We like assisting entrepreneurs that wish to develop an MVP for their concepts.</h2>
                            <p className="lead">We are capable of creating your entire product. </p>
                            <ul style={liBulletWhite}>
                                <li className="lead ps-1 mb-3">Join us in planning, designing, developing, testing, and deploying your product with 100% transparency. </li>
                                <li className="lead ps-1 mb-3">Project-focused pricing and planning. </li>
                                <li className="lead ps-1 mb-3">Works well for corporations, enterprises, and startups.</li>
                            </ul>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                <Link to={'contact-us'} type="button" className="btn px-4 me-md-2 mt-2 defaultButton" onClick={moveTop}>
                                    <span />
                                    Start Your Project
                                </Link>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <ServicesCards />
            <Technologies />
            <Solutions />
            <HeroSection />
        </Stack>
    )
}

export default Home
