import React from 'react'
import { Container, Row, Stack } from 'react-bootstrap'
import Background from '../assets/master/images/backgrounds/product-banner.webp'
import HeroSection from './components/HeroSection'
import ServicesCards from './components/ServicesCards'
import TeamMembers from './components/TeamMembers'
function OurServices() {
    document.title = 'OwaSoft - Products'
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
    }
    return (
        <Stack className="main">
            <Container fluid className="pt-5" style={MainBanner}>
                <Container fluid="xl">
                    <Row className="col-12 col-md-6 flex-column align-content-center justify-content-center my-5 py-md-5">
                        <h1 className="display-3 text-white fw-bold">OUR SERVICES</h1>
                        <h6 className="my-3 text-white lead">Take a look at our work within the developer community, our open-source contributions and our premium products made just for you.</h6>
                        <h6 className="m-0 text-white lead">
                            turning ideas into <span className="mainColor"> our services </span>
                        </h6>
                    </Row>
                </Container>
            </Container>
            <ServicesCards />
            <HeroSection />
        </Stack>
    )
}

export default OurServices
