import React from 'react'

import { Stack, Container, Row} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import background from '../../assets/master/images/backgrounds/main-video-section.png'

export default function VideoSection() {
    const mainVideoSection = {
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${background})`,
    }
    function moveTop() {
        document.documentElement.scrollTop = 0
    }
    return (
        <Stack style={mainVideoSection}>
            <Container fluid="xl py-3">
                <Row className="py-3 py-md-5 flex-md-row-reverse">
                    <div className="col col-md-6">
                        <iframe className="w-100 rounded-3" height="315" src="https://www.youtube.com/embed/FlPZNbKdu_8?start=20" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
                    </div>
                    <Stack className="col-md-6 pe-lg-5">
                        <h2 className=" fw-bold mb-3 mainColor mt-4 mt-sm-0">Trusted by</h2>
                        <p className="lead defaultText">Leading companies from all around the world entrust us with innovating and developing tailored solutions for them.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <Link to={'contact-us'} type="button" className="btn px-4 me-md-2 mt-2 defaultButton" onClick={moveTop}>
                                <span />
                                Talk to Us
                            </Link>
                        </div>
                    </Stack>
                </Row>
            </Container>
        </Stack>
    )
}
