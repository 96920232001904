import React from 'react'
import { Row, Container, Stack, Image, Card } from 'react-bootstrap'
import '../../assets/master/css/caseStudyDetails.css'

import Background from '../../assets/master/images/backgrounds/product-banner.webp'
import Logo from '../../assets/master/images/card-icons/hajiLogo.webp'
import BannerImage from '../../assets/master/images/cover/HajiBabaCover.webp'
import HeroSection from '../components/HeroSection'
import backgroundImage4 from '../../assets/master/images/backgrounds/bg-4.webp'
import heroImage4 from '../../assets/master/images/cover/hero-6.webp'
import backgroundImage5 from '../../assets/master/images/backgrounds/bg-8.webp'

import Background001 from '../../assets/master/images/backgrounds/bg-6.webp'
import Icon1 from '../../assets/master/images/card-icons/icon-30.webp'
import Icon2 from '../../assets/master/images/card-icons/icon-31.webp'
import Icon3 from '../../assets/master/images/card-icons/icon-32.webp'
import Icon4 from '../../assets/master/images/card-icons/icon-33.webp'
import Icon5 from '../../assets/master/images/card-icons/icon-34.webp'

import caseStudyCardImage1 from '../../assets/master/images/card-icons/icon-35.png'
import caseStudyCardImage2 from '../../assets/master/images/card-icons/icon-36.png'
import caseStudyCardImage3 from '../../assets/master/images/card-icons/icon-37.png'
import caseStudyCardImage4 from '../../assets/master/images/card-icons/icon-38.png'
import caseStudyCardImage5 from '../../assets/master/images/card-icons/icon-39.png'
import caseStudyCardImage6 from '../../assets/master/images/card-icons/icon-40.png'

import problemsImage from '../../assets/master/images/cover/Group26654@3x.png'

import RequirementsImg1 from '../../assets/master/images/card-icons/icon-40.webp'
import RequirementsImg2 from '../../assets/master/images/card-icons/icon-41.webp'
import RequirementsImg3 from '../../assets/master/images/card-icons/icon-42.webp'

import developmentImage from '../../assets/master/images/cover/Group26655@3x.png'
import challengeImage from '../../assets/master/images/cover/Group19809@3x.png'
import viewImage from '../../assets/master/images/cover/Group26784@3x.png'
import liBulletsWhite from '../../assets/master/images/listIconWhite.webp'
function HajiBaba() {
    const MainBanner = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
    }
    const Background01 = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background001})`,
    }
    const background4 = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage4})`,
    }
    const background5 = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage5})`,
    }
    const liBulletWhite = {
        listStyleImage: `url( ${liBulletsWhite} )`,
    }
    document.title = 'HAJI BABA | OwaSoft'
    return (
        <Stack className="main">
            <Container fluid className="pt-5" style={MainBanner}>
                <Container fluid="xl">
                    <Row className="position-relative my-5 py-3 py-md-5">
                        <div className="col-12 ">
                            <Image className="mb-2 img-fluid" width="100" src={Logo} loading="lazy" alt="card Image" />
                            <h1 className="display-4 text-white fw-bold">HAJI BABA</h1>
                        </div>
                        <div className="col-6 position-absolute float-end end-0 MainBannerImage d-none d-lg-block">
                            <Image src={BannerImage} loading="lazy" alt="card Image" className="w-100" />
                        </div>
                    </Row>
                </Container>
            </Container>
            <Stack style={Background01} className="py-5">
                <Container fluid="xl">
                    <Row className="pt-2 pt-lg-5 mt-lg-5 position-relative justify-content-center justify-content-md-between flex-wap align-items-start">
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon1} />
                            <Card.Body className="text-center">
                                <Card.Title>Timeline</Card.Title>
                                <Card.Text className="defaultText">6 Months</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon2} />
                            <Card.Body className="text-center">
                                <Card.Title>Industry</Card.Title>
                                <Card.Text className="defaultText">eCommerce</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon3} />
                            <Card.Body className="text-center">
                                <Card.Title>Location</Card.Title>
                                <Card.Text className="defaultText">United Kingdom</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon4} />
                            <Card.Body className="text-center">
                                <Card.Title>Project</Card.Title>
                                <Card.Text className="defaultText">Development</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 mx-2 mb-3" style={{ width: '11em' }}>
                            <Card.Img loading="lazy" variant="top" src={Icon5} />
                            <Card.Body className="text-center">
                                <Card.Title>Year</Card.Title>
                                <Card.Text className="defaultText">2021</Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </Stack>
            <Container fluid="xl">
                <Row className="pt-2 px-4 px-lg-0 justify-content-center justify-content-md-around flex-wap align-items-start">
                    <div className="bg-transparent py-4 mx-3 case-study-card-1 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage1} />
                        <Card.Body>
                            <Card.Title className="my-3">Strategy</Card.Title>
                            <Card.Text className="defaultText">In this project, we joined hands with Haji Baba Halal Meat Online company name across the U.K offering authentic halal meat online. They need a mobile app for their customers, and we must develop 2 apps one for customers and second for stores</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-2 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage2} />
                        <Card.Body>
                            <Card.Title className="my-3">Analysis Planning</Card.Title>
                            <Card.Text className="defaultText">Considering the client wanted a fast, efficient, and attractive Mobile app for android and IOS, we decided to use Flutter for Mobile app and ASP .NET Core for Backend development as the desired technologies to work with.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-3 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage3} />
                        <Card.Body>
                            <Card.Title className="my-3">UI/UX Design</Card.Title>
                            <Card.Text className="defaultText">The most Important part of this project to design an attractive UI/UX Design, so we have Design both apps’ screens first and approve my client and test for UX as well.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-4 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage4} />
                        <Card.Body>
                            <Card.Title className="my-3">Development</Card.Title>
                            <Card.Text className="defaultText">During the development phase, our team worked on converting UI/UX design into flutter frontend code and APIs development side by side in ASP .NET Core. After completing frontend and APIs development we will integrate APIs with frontend and admin panel.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-5 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage5} />
                        <Card.Body>
                            <Card.Title className="my-3">Testing</Card.Title>
                            <Card.Text className="defaultText">Testing was essential to ensure that the app was up to the mark because of which we roped in one QA Team who conducted manual testing for the project.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                    <div className="bg-transparent py-4 mx-3 case-study-card-6 position-relative col-md-3 col-sm-5 col-xs-12">
                        <Card.Img loading="lazy" variant="top w-25" src={caseStudyCardImage6} />
                        <Card.Body>
                            <Card.Title className="my-3">Delivery</Card.Title>
                            <Card.Text className="defaultText">The work on the project is finalized in January 2023, and the client is extremely happy with our services that have been made and how the System is turning out to be.</Card.Text>
                        </Card.Body>
                        <span className="position-absolute" />
                        <span className="position-absolute" />
                    </div>
                </Row>
            </Container>
            <Stack style={background4} className="mt-4">
                <Container fluid="xl">
                    <Row className="flex-lg-row-reverse py-5">
                        <Stack className="col col-lg-5">
                            <Image className="w-100" src={heroImage4} alt="card Image" loading="lazy" />
                        </Stack>
                        <Stack className="col-lg-7 text-white mt-5 mt-lg-0">
                            <h1 className="display-6 fw-bold">About the Client</h1>
                            <p className="lead pt-3">Haji Baba Halal Meat Online has become a trusted household name across the U.K offering authentic halal meat online, with delivery and pickup form your nearest ASAD store at Hajib Baba counters, we have more then 50+ halal meat provider stores in ASDA and which cover West London, East London, North London, Cardiff and many other locations. With an extensive range of Halal Chicken, Halal Muton, Halal Beef, Halal Lamb, Halal Sheep, Marinated and organic halal meat- we’ve got it! With farm to fork traceability we ensure to source only the best of produce helping you to eat better and healthier.</p>
                        </Stack>
                    </Row>
                </Container>
            </Stack>
            <Stack>
                <Container fluid="xl">
                    <Row className="pt-5">
                        <div>
                            <div className=" col-md-4 float-start ms-0 ms-lg-5 me-lg-5">
                                <Image className="w-100" src={problemsImage} alt="card Image" loading="lazy" />
                            </div>
                            <h1 className="display-6 fw-bold mainColor mt-5 mt-lg-0">The Problem</h1>
                            <p className="lead">The HAJI BABA Company don’t have mobile app for customers where they can order meat online, they need 2 apps one for customers where customer can order their product and one for the Stores where they received orders from customers, and we have full fill all the requirements for both apps.</p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="py-5">
                        <h1 className="display-6 fw-bold my-3 mainColor">Team On-Call</h1>
                        <p className="lead defaultText">After understanding the requirements that the project would consist of, we roped in one designer, flutter Developer, ASP .NET Developer, and a project manager to handle the project as directed by the client. Considering the project is quite different from what we usually do at Owasoft, the team had to spend quite some time catching the drift of what the client wanted and coming up with ideas that matched their requisites.</p>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background4}>
                <Container fluid="xl">
                    <Row className="py-5 justify-content-around">
                        <h1 className="display-6 fw-bold mb-5 text-white text-center">Requirements</h1>
                        <Card className="bg-transparent border-0 m-3 d col-md-3">
                            <Card.Img loading="lazy" variant="top" className="w-50 m-auto" src={RequirementsImg3} />
                            <Card.Body className="text-center">
                                <Card.Text className="text-white"> Develop Admin panel for manage products and sales.</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 m-3 d col-md-3">
                            <Card.Img loading="lazy" variant="top" className="w-50 m-auto" src={RequirementsImg2} />
                            <Card.Body className="text-center">
                                <Card.Text className="text-white">Develop Customer app where customers can order their products.</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0 m-3 d col-md-3">
                            <Card.Img loading="lazy" variant="top" className="w-50 m-auto" src={RequirementsImg1} />
                            <Card.Body className="text-center">
                                <Card.Text className="text-white">Develop Store app where Store manager can see the orders from the customer and manage orders</Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </Stack>
            <Stack>
                <Container fluid="xl">
                    <Row className="pt-5">
                        <div>
                            <div className=" col-md-4 float-end ms-0 ms-lg-5">
                                <Image className="w-100" src={developmentImage} alt="card Image" loading="lazy" />
                            </div>
                            <h1 className="display-6 fw-bold mainColor mt-4 mt-lg-0">Development</h1>
                            <p className="lead">After the team was introduced to the client, we spent the initial few days brainstorming and researching how things need to be done based on the requirements and planning the way forward. After that start development Applications frontend in flutter by using approved design from client.</p>
                            <p className="lead">Then one of our developer starts working on its APIs and admin panel. When all frontend of mobile applications and APIs are ready then we starts integrations of APIs into mobile app frontend to achieve the actual requirements. After that we must finish admin panel.</p>
                            <p className="lead">After completing functionalities, we will start QA on the mobile app and write documentation for it and fix any kind of issue related to the development and UX.</p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background5}>
                <Container fluid="xl">
                    <Row className="py-5 mt-5">
                        <div>
                            <div className=" col-md-6 float-start me-5 mb-4">
                                <Image className="w-100" src={challengeImage} alt="card image" loading="lazy" />
                            </div>
                            <h1 className="display-6 fw-bold mainColor mt-lg-0">Overcoming Challenges</h1>
                            <p className="lead">One of the major Challenge for us is to develop 2 apps and one admin panel and connect all three with each other. where we discussed what needs to be done to take the project forward. Another challenge for us to implement 2 types of discounts on products one is bulk discount and other is flat discount.</p>
                            <p className="lead">However, our team conducted extensive studies and researched the niche industry and the client's competitors which helped them to come up with creative ideas. We coordinated with them proactively to understand what they required and took the necessary feedback and implemented the changes one Screen at a time before moving on to the next one.</p>
                        </div>
                    </Row>
                </Container>
            </Stack>
            <Stack style={background4}>
                <Container fluid="xl">
                    <Row className="flex-lg-row-reverse py-5">
                        <Stack className="col col-lg-5 mb-5">
                            <Image className="w-100" src={viewImage} alt="card image" loading="lazy" />
                        </Stack>
                        <Stack className="col-lg-6 text-white">
                            <h1 className="display-6 fw-bold mb-3">In bird's eye view</h1>
                            <ul style={liBulletWhite}>
                                <li className="lead ps-1">The Improve UI/UX designs using Adobe XD</li>
                                <li className="lead ps-1">VPS Server was used for server deployment.</li>
                                <li className="lead ps-1"> The tool used to automate the testing process was Gitlab</li>
                                <li className="lead ps-1"> Slack was the tool of choice for project management.</li>
                            </ul>
                        </Stack>
                        <h1 className="display-6 fw-bold text-white mb-3 mt-4">Final Impression</h1>
                        <p className="lead text-white">The project is completed with the development and implementation at the final deadline. The Mobile application is currently Running in real-time and our partner’s satisfaction with our services is exemplified. We are delighted to have collaborated with our client for expansion, we foresee a long-term visibility of the project which we hope to execute in collaboration with our partner.</p>
                    </Row>
                </Container>
            </Stack>
            <HeroSection />
        </Stack>
    )
}

export default HajiBaba
