import React from 'react'
import { Container, Image, Row, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import eCommerce from '../../assets/master/images/card-icons/solutions/eCommerce.svg'
import Finance from '../../assets/master/images/card-icons/solutions/Finance.svg'
import Manufacturing from '../../assets/master/images/card-icons/solutions/Manufacturing.svg'
import Healthcare from '../../assets/master/images/card-icons/solutions/Healthcare.svg'
import Entertainment from '../../assets/master/images/card-icons/solutions/Entertainment.svg'
import Education from '../../assets/master/images/card-icons/solutions/Education.svg'
import Travel from '../../assets/master/images/card-icons/solutions/Travel.svg'
import Communication from '../../assets/master/images/card-icons/solutions/Communication.svg'
import Booking from '../../assets/master/images/card-icons/solutions/Booking.svg'
import RealEstate from '../../assets/master/images/card-icons/solutions/RealEstate.svg'
import Beverages from '../../assets/master/images/card-icons/solutions/Beverages.svg'
import Marketing from '../../assets/master/images/card-icons/solutions/Marketing.svg'

function ServicesCards() {
    const cardsData = [
        {
            title: 'eCommerce',
            color: '#f26c6d',
            icon: eCommerce,
            description: 'Online marketplace development',
            path: '#',
        },
        {
            title: 'Finance, Banking And Insurance',
            color: '#65c6f1',
            icon: Finance,
            description: 'Finance, Banking and Insurance website and app Development',
            path: '#',
        },
        {
            title: 'Manufacturing',
            color: '#efc245',
            icon: Manufacturing,
            description: 'Manufacturing industry Software and IT Solutions',
            path: '#',
        },
        {
            title: 'Healthcare App Development Services',
            color: '#f9435e',
            icon: Healthcare,
            description: 'Playing a role in advancing digital healthcare through the integration of technology',
            path: '#',
        },
        {
            title: 'Entertainment',
            color: '#26d9bb',
            icon: Entertainment,
            description: 'Entertainment Software, Web & App Development',
            path: '#',
        },
        {
            title: 'Education',
            color: '#668aed',
            icon: Education,
            description: 'Education Software, Web & App Development',
            path: '#',
        },
        {
            title: 'Travel & Hospitality',
            color: '#f662ae',
            icon: Travel,
            description: 'Travel & Hospitality Software, Web & App Development',
            path: '#',
        },
        {
            title: 'Social Media & Communication',
            color: '#9ee67a',
            icon: Communication,
            description: 'Social Media Software, Web & App Development',
            path: '#',
        },
        {
            title: 'On-Demand Booking',
            color: '#faa258',
            icon: Booking,
            description: 'On-Demand Software, Web & App Development',
            path: '#',
        },
        {
            title: 'Real-Estate',
            color: '#65c6f1',
            icon: RealEstate,
            description: ' Real Estate Software, Web & App Development',
            path: '#',
        },
        {
            title: 'Food & Beverages',
            color: '#efc245',
            icon: Beverages,
            description: 'On-Demand Delivery Software, Web & App Development',
            path: '#',
        },
        {
            title: 'Marketing',
            color: '#f9435e',
            icon: Marketing,
            description: 'Marketing Software, Web & app Development',
            path: '#',
        },
    ]

    return (
        <Stack>
            <Container fluid="xl">
                <Row className="m-auto align-items-center py-5">
                    <div className="col-lg-12">
                        <h1 className="display-6 fw-bold lh-1 mb-3 mainColor">Solutions</h1>
                        <p className="lead">We help revolutionize industries and transform them digitally with our tailored solutions, designed only to take them to the top.</p>
                    </div>
                    <div className="col-12">
                        <div className="row justify-content-center g-4">
                            {cardsData.map((servicesData) => {
                                return (
                                    <Link to={'../uiUx'} className="col-md-4 col-sm-6 text-decoration-none">
                                        <div className="p-4 gap-3 d-flex flex-column align-items-center position-relative workCard h-100">
                                            <span className="position-absolute" style={{ borderRight: servicesData.color + ' 35px solid' }}></span>
                                            <Image src={servicesData.icon} alt={servicesData.title + 'Image'} loading="lazy" />
                                            <h5 className="text-dark text-center">{servicesData.title}</h5>
                                            <p className="card-text text-center defaultText">{servicesData.description}</p>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </Row>
            </Container>
        </Stack>
    )
}

export default ServicesCards
