import React from 'react'
import { Outlet } from 'react-router-dom'
import MenuBar from '../master/components/MenuBar'
import Footer from '../master/components/Footer'
import '../assets/master/css/Main.css'
function master() {
    return (
        <>
            <MenuBar />
            <Outlet />
            <Footer />
        </>
    )
}

export default master
