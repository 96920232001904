import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Master from './layouts/Master'

import Home from './master/Home'
import Services from './master/OurServices'
import UiUx from './master/services/UiUx'
import SolidJs from './master/services/SolidJs'
import OurWork from './master/OurWork'
import JusATutor from './master/our_work/JusATutor'
import GiantCart from './master/our_work/GiantCart'
import HajiBaba from './master/our_work/HajiBaba'
import ShambaPro from './master/our_work/ShambaPro'
import SurveyMobileApp from './master/our_work/SurveyMobileApp'
import PakVisa from './master/our_work/PakVisa'
import AstroHouz from './master/our_work/AstroHouz'
import Afiye from './master/our_work/Afiye'
import JDiamond from './master/our_work/JDiamond'
import ClinicManagementSystem from './master/our_work/ClinicManagementSystem'
import ContactUs from './master/ContactUs'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Master />}>
                    <Route index element={<Home />} />
                    <Route path="services" element={<Services />} />
                    <Route path="services/solid-js" element={<SolidJs />} />
                    <Route path="services/ui-ux" element={<UiUx />} />
                    <Route path="our-work" element={<OurWork />} />
                    <Route path="our-work/jus-a-tutor" element={<JusATutor />} />
                    <Route path="our-work/GiantCart" element={<GiantCart />} />
                    <Route path="our-work/haji-baba" element={<HajiBaba />} />
                    <Route path="our-work/shamba-pro" element={<ShambaPro />} />
                    <Route path="our-work/survey-mobile-app" element={<SurveyMobileApp />} />
                    <Route path="our-work/pak-visa" element={<PakVisa />} />
                    <Route path="our-work/astro-houz" element={<AstroHouz />} />
                    <Route path="our-work/afiye" element={<Afiye />} />
                    <Route path="our-work/clinic-management-system" element={<ClinicManagementSystem />} />
                    <Route path="j-diamond" element={<JDiamond />} />
                    <Route path="contact-us" element={<ContactUs />} />
                    <Route path="error_404" element={<ContactUs />} />
                    <Route path="*" element={<Navigate to={'error_404'} />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
)
reportWebVitals()
