import { React, useRef, useState, useEffect } from 'react'
import Slider from 'react-slick'
import { Container, Row, Image, Stack } from 'react-bootstrap'

import heroSection1 from '../../assets/master/images/backgrounds/bg-2.webp'
import GiantCart from '../../assets/master/images/cover/GiantCart.webp'
import GiantCartLogo from '../../assets/master/images/brands/GiantCart.webp'
import GiantCartLogoLight from '../../assets/master/images/brands/GiantCartLogoLight.webp'
import HajiBaba from '../../assets/master/images/cover/HajiBaba.webp'
import HajiBabatLogo from '../../assets/master/images/brands/HajiBaba.webp'
import HajiBabatLogoLight from '../../assets/master/images/brands/HajiBabatLogoLight.webp'
import JusATutor from '../../assets/master/images/cover/JusATutor.webp'
import JusATutorLogo from '../../assets/master/images/brands/JusATutor.webp'
import JusATutorLogoLight from '../../assets/master/images/brands/JusATutorLogoLight.webp'
import PakVisa from '../../assets/master/images/cover/PakVisa.webp'
import PakVisaLogo from '../../assets/master/images/brands/PakVisa.webp'
import PakVisaLogoLight from '../../assets/master/images/brands/PakVisaLogoLight.webp'
import Shambapro from '../../assets/master/images/cover/Shambapro.webp'
import ShambaproLogo from '../../assets/master/images/brands/Shambapro.webp'
import ShambaproLogoLight from '../../assets/master/images/brands/ShambaproLogoLight.webp'
import Survey from '../../assets/master/images/cover/Survey.webp'
import SurveyLogo from '../../assets/master/images/brands/Survey.webp'
import SurveyLogoLight from '../../assets/master/images/brands/SurveyLogoLight.webp'
import AstroHouz from '../../assets/master/images/cover/AstroHouz.webp'
import AstroHouzLogo from '../../assets/master/images/brands/AstroHouz.webp'
import AstroHouzLogoLight from '../../assets/master/images/brands/AstroHouzLogoLight.webp'
import Diamond from '../../assets/master/images/cover/Diamond.webp'
import DiamondLogo from '../../assets/master/images/brands/Diamond.webp'
import DiamondLogoLight from '../../assets/master/images/brands/DiamondLogoLight.webp'
import Afiye from '../../assets/master/images/cover/Afiye.webp'
import AfiyeLogo from '../../assets/master/images/brands/Afiye.webp'
import AfiyeLogoLight from '../../assets/master/images/brands/AfiyeLogoLight.webp'
import Clinic from '../../assets/master/images/cover/Clinic.webp'
import ClinicLogo from '../../assets/master/images/brands/Clinic.webp'
import ClinicLogoLight from '../../assets/master/images/brands/ClinicLogoLight.webp'



import 'slick-carousel/slick/slick.css'
import { Link } from 'react-router-dom'

export default function Brands() {
    const sliderContent = [
        {
            title: 'Jus A Tutor',
            image: JusATutor,
            description: 'Jus A Tutor is an online tutoring Platform Whare High Qualified Mathematics Teacher Can Enter Questions and with different level and Teacher have only access to the specific level. Also, Teacher can update and delete their Questions as well. Student can Register their account and can only See the questions answers and can download it in... ',
        },
        {
            title: 'Haji Baba',
            image: HajiBaba,
            description: 'Haji Baba is a United Kingdom Base Halal Meat Company. It has 70+ store in United Kingdom and we are working with since October 2021. We have developed its mobile application for their customers and for their stores. Customer can Use application for Registering their account and Order for Halal Meat. Customer can tack their orders and can Pay...',
        },
        {
            title: 'GiantCart',
            image: GiantCart,
            description: 'GiantCart is an Ecommerce Website where customers and Vendors can register their accounts. Vendor can upload their products and each product upload with 3 prices (Regular, Bulk and B2B). Vendors can also update and delete their product from dashboard and track their orders as well. Customer can order any Vendor’s products with promo code; Vendor...',
        },
        {
            title: 'PakVisa',
            image: PakVisa,
            description: 'PakVisa Is a Website of Real Estate where Users can post their ads about buying and selling properties. User can SignUP their account and then Login to their account for post their ads. Users can feature their ads and for feature user must buy some credits and use these credits for the featuring their ads. Feature ads show on the top of the website...',
        },
        {
            title: 'Shambapro',
            image: Shambapro,
            description: 'We have been working with Shambapro app for 2 Years. Shambapro enables small scale farmers in Africa access suitable agriculture financing without traditional collateral and other value chain services like ready and better markets, quality affordable inputs, agriculture extension services and technical support etc.Small Scale Farmers can access the platform...',
        },
        {
            title: 'Survey',
            image: Survey,
            description: 'Survey App is an App for the pollster and managers. Which will serve the purpose of getting done different surveys with the help of a mobile application. The pollster will have assigned surveys to them and where they can go to different people and fill in the forms according to their answers. The managers can have the analysis of the surveys based on locations...',
        },
        {
            title: 'Afiye Website',
            image: Afiye,
            description: `Afiye is a real estate and rental marketplace committed to empowering our customers with relevant information about prospective homes and connecting them with the best local professionals who can help. Afiye’s mission is to be involved in the full cycle of owning and living in a home from buying, selling, renting, and financing. It starts with... `,
        },
        {
            title: 'Clinic Management System',
            image: Clinic,
            description: 'Our Clinic Management system is Deployed on 50+ Clinic all over Pakistan, in this system we are handling patients, patients’ appointments, patients treatment as per the appointment, appointment charges, appointments lagers, Sale Reports, Expense report, Role base, User management, Access privilege...',
        },
        {
            title: 'AstroHouz',
            image: AstroHouz,
            description: 'AstroHouz is an online web Platform that works all around the world. We have solved problem for people who need astrologist’s services so they can order astrology services online. Same for the Astrologist they can sale their services to all the over the world online. And they can earn money by selling their services like Puja (audio, Video), Reporting...',
        },
        {
            title: 'J Diamond Mobile App',
            image: Diamond,
            description: 'JDiamond is the first Qatari online retail shop specializing in essential products for Men with a vision of helping men to have their essential products by the most trustworthy online platform and the most appropriate customer experience. We have Done Some Upgradation on its Android and IOS Mobile Applications...',
        },
    ]

    const brandStyle = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${heroSection1})`,
    }

    function moveTop() {
        document.documentElement.scrollTop = 0
    }
    const brandsRef = useRef(null)

    const settings = {
        dots: false,
        centerPadding: '100px',
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    autoplay: false,
                },
            },
        ],
    }

    const [filter, setFilter] = useState('Jus A Tutor')
    const [projects, setProjects] = useState([])
    useEffect(() => {
        setProjects(sliderContent)
    }, [])
    useEffect(() => {
        setProjects([])
        const filtered = sliderContent.map((p) => ({ ...p, filtered: p.title.includes(filter) }))
        setProjects(filtered)
    }, [filter])
    return (
        <Stack style={brandStyle}>
            <Container fluid="xl" className="my-3">
                <Row className="justify-content-between mb-4">
                    <div className="col-6 col-md-9">
                        <Slider {...settings} ref={brandsRef} className="brandLogos">
                            <div className={`logo ${filter === 'Jus A Tutor' ? 'active' : ''}`} onClick={() => setFilter('Jus A Tutor')}>
                                <Image className="w-100" src={filter === 'Jus A Tutor' ? JusATutorLogoLight : JusATutorLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'Haji Baba' ? 'active' : ''}`} onClick={() => setFilter('Haji Baba')}>
                                <Image className="w-100" src={filter === 'Haji Baba' ? HajiBabatLogoLight : HajiBabatLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'GiantCart' ? 'active' : ''}`} onClick={() => setFilter('GiantCart')}>
                                <Image className="w-100" src={filter === 'GiantCart' ? GiantCartLogoLight : GiantCartLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'PakVisa' ? 'active' : ''}`} onClick={() => setFilter('PakVisa')}>
                                <Image className="w-100" src={filter === 'PakVisa' ? PakVisaLogoLight : PakVisaLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'Shambapro' ? 'active' : ''}`} onClick={() => setFilter('Shambapro')}>
                                <Image className="w-100" src={filter === 'Shambapro' ? ShambaproLogoLight : ShambaproLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'Survey' ? 'active' : ''}`} onClick={() => setFilter('Survey')}>
                                <Image className="w-100" src={filter === 'Survey' ? SurveyLogoLight : SurveyLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'Afiye Website' ? 'active' : ''}`} onClick={() => setFilter('Afiye Website')}>
                                <Image className="w-100" src={filter === 'Afiye Website' ? AfiyeLogoLight : AfiyeLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'Clinic Management System' ? 'active' : ''}`} onClick={() => setFilter('Clinic Management System')}>
                                <Image className="w-100" src={filter === 'Clinic Management System' ? ClinicLogoLight : ClinicLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'AstroHouz' ? 'active' : ''}`} onClick={() => setFilter('AstroHouz')}>
                                <Image className="w-100" src={filter === 'AstroHouz' ? AstroHouzLogoLight : AstroHouzLogo} alt="Logo" loading="lazy" />
                            </div>
                            <div className={`logo ${filter === 'J Diamond Mobile App' ? 'active' : ''}`} onClick={() => setFilter('J Diamond Mobile App')}>
                                <Image className="w-100" src={filter === 'J Diamond Mobile App' ? DiamondLogoLight : DiamondLogo} alt="Logo" loading="lazy" />
                            </div>
                        </Slider>
                    </div>
                    <div className="col-6 col-md-3 brandButtons d-flex justify-content-end align-items-center">
                        <button className="d-flex justify-content-center align-items-center" onClick={() => brandsRef.current.slickPrev()}>
                            <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />{' '}
                            </svg>
                        </button>
                        <button className="d-flex justify-content-center align-items-center" onClick={() => brandsRef.current.slickNext()}>
                            <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />{' '}
                            </svg>
                        </button>
                    </div>
                </Row>
                <Row className="py-3 flex-md-row-reverse">
                    {projects.map((item) =>
                        item.filtered === true ? (
                            <>
                                <div className="col-md-6 ps-md-5 ps-0 pe-0">
                                    <Image className="w-100" src={item.image} alt={item.title} loading="lazy" />
                                </div>
                                <div className="col-md-6">
                                    <p className="lead defaultText text-justify">{item.description}</p>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                        <Link to={'/contact-us'} type="button" className="btn px-4 me-md-2 defaultButton" onClick={moveTop}>
                                            <span />
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )
                    )}
                </Row>
            </Container>
        </Stack>
    )
}