import React from 'react'
import { Container, Image, Row, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import UIUX from '../../assets/master/images/card-icons/icon-1.webp'
import WebsiteDesigning from '../../assets/master/images/card-icons/icon-2.webp'
import SolidJavaScript from '../../assets/master/images/card-icons/icon-3.webp'
import Development from '../../assets/master/images/card-icons/icon-4.webp'
import Devp from '../../assets/master/images/card-icons/icon-5.webp'
import Quality from '../../assets/master/images/card-icons/icon-6.webp'
import DevOps from '../../assets/master/images/card-icons/icon-7.webp'
import Project from '../../assets/master/images/card-icons/icon-8.webp'
import Maintenance from '../../assets/master/images/card-icons/icon-9.webp'

function ServicesCards() {
    const cardsData = [
        {
            title: 'UI/UX Design',
            color: '#faa258',
            icon: UIUX,
            description: 'Customized designs tailored to your product, ensuring a seamless user experience and visually appealing aesthetic!',
            path: 'ui-ux',
        },
        {
            title: 'Website Designing',
            color: '#9ee67a',
            icon: WebsiteDesigning,
            description: 'Crafting stunning and effective website’s Design that drive outstating results',
            path: 'web-designing',
        },
        {
            title: 'Solid JavaScript',
            color: '#efc245',
            icon: SolidJavaScript,
            description: 'Vue.js is an open-source reactive JavaScript framework that enables the creation of smooth and intuitive user interfaces.',
            path: 'solid-js',
        },
        {
            title: 'API Development',
            color: '#e27ef2',
            icon: Development,
            description: 'Expertly crafted API development to ensure your apps and features have optimal uptime and drive maximum revenue.',
            path: 'api-development',
        },
        {
            title: 'Web & Mobile App Development',
            color: '#f662ae',
            icon: Devp,
            description: 'Employing advanced technologies such as React, React Native, Flutter, and others to ensure your products are scalable and ready for the future.',
            path: 'web-mobile-app-development',
        },
        {
            title: 'Quality Assurance',
            color: '#52d788',
            icon: Quality,
            description: 'We prioritize delivering high quality products, consistently exceeding your expectations, and delivering exceptional results.',
            path: 'uiUx',
        },
        {
            title: 'DevOps',
            color: '#26d9bb',
            icon: DevOps,
            description: 'Iterative development and automation for fast, efficient product deployment and demonstration',
            path: 'eCom',
        },
        {
            title: 'Project management',
            color: '#65c6f1',
            icon: Project,
            description: 'Trust us to push your project from start to finish, ensuring the best possible outcome and direction.',
            path: 'uiUx',
        },
        {
            title: 'Maintenance & Upgrades',
            color: '#f26c6d',
            icon: Maintenance,
            description: 'Our support continues even after the product is complete, with ongoing assistance every step of the way.',
            path: 'uiUx',
        },
    ]
    function moveTop() {
        document.documentElement.scrollTop = 0
    }
    return (
        <Stack>
            <Container fluid="xl">
                <Row className="m-auto align-items-center py-5">
                    <div className="col-lg-6">
                        <h1 className="display-6 fw-bold lh-1 mb-3 mainColor">What We Do Best</h1>
                    </div>
                    <div className="col-lg-6">
                        <p>With over 500 completed projects under our belt, Owasoft is a seasoned player in the field of mobile and web app development. From design to deployment, we handle every aspect of app creation in-house, ensuring a seamless and high-quality product. Our expertise spans the entire development process, from A to Z, and we pride ourselves on our ability to craft visually stunning and functionally apps.</p>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="row justify-content-center g-4">
                            {cardsData.map((servicesData) => {
                                return (
                                    <Link to={'/services/' + servicesData.path} className="col-md-4 col-sm-6 text-decoration-none" onClick={moveTop}>
                                        <div className="p-4 gap-3 d-flex flex-column align-items-center position-relative workCard h-100">
                                            <span className="position-absolute" style={{ borderRight: servicesData.color + ' 35px solid' }}></span>
                                            <Image src={servicesData.icon} alt={servicesData.title + 'Image'} loading="lazy" />
                                            <h5 className="text-dark">{servicesData.title}</h5>
                                            <p className="card-text text-center defaultText">{servicesData.description}</p>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </Row>
            </Container>
        </Stack>
    )
}

export default ServicesCards
